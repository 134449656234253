.ReportHeader {
  font-family: 'Open Sans'!important;
  font-weight: 800!important;
  color: #36495D!important;
}

.center {
  width: 100%;
  text-align: center;
}

.ReportHeaderLight {
  font-weight: 700!important;
}


.ReportContainer .placeholder {
  animation: none!important;
}

.ReportContainer .line, .ReportContainer .paragraph::before {
  background-color: #F5F6F8!important;
}

@media  screen and (max-width: 600px) {
 .report-icon {

  display: none!important;

  }
}

#page-1.Print, #page-2.Print{
  width: 1161px!important;
}