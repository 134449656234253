.slide-container {
  margin: auto;
  display: inline;
  margin-bottom: 100px;
}
.citations {
  /* background-image: url('../../assets/images/uzman-bg.png') !important; */
  margin: auto;
}

.citations-header{
  font-family: 'Open Sans';
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: bold;
}
.each-fade {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExpertHeader{
  font-size: 14px;
}
.each-fade .image-container {
  margin-left: 50px;
  position: relative;
  float: left;
}

.each-fade .img-container {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 150px;
  height: 150px;
  border:'2px groove #39718D';
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  overflow: visible;
  border-top-color: transparent; 
}

.each-fade .quote-mark {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  overflow: visible;
  border-top-color: transparent;
}

.each-fade .author-container {
  color: #39BA9B;
  font-size: 20px;
  font-weight: 900;
  margin-top: 90px;
}


.each-fade .text-container {
  float: right;
  background: white;
  width: 800px;
  height: 280px;
  position:relative;
  margin-top: 75px;  
}

.each-fade .text {
  margin:15px;  
}

.PageDown {
  vertical-align: middle !important;
  animation: pulse 2s linear alternate-reverse infinite;
  cursor: pointer;
  text-align: center;
  bottom: 1em;
  position: absolute;
  left: 45%;
  line-height: 1;
}
@keyframes pulse {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.LandingHeading {
  background-color: white;
  position: relative;
  display: flex;
  font-size: 3vw;
  width: 100%;
}

.LandingHeading .LandingHeading-Container {
  flex-grow: 1;
}
.LandingHeading .LandingHeadingHeader {
  font-size: calc(8px + 2.5vw);
  line-height: 1.2em;
  font-family: Arial;
  font-weight: lighter;
  letter-spacing: -2px;
}

.LandingSubHeader {
  font-size: calc(10px + 0.5vw);
  line-height: 1.2em;
}

.LandingDescriptionLeft {
  color: #bcc1c5;
}

.LandingDescriptionLeft .Brainquire {
  font-weight: bold;
  font-size: calc(50px + 1.5vw);
  align-items: center;
  display: flex !important;
  margin-bottom: 0.2em;
}

.LandingDescriptionLeft .Inventory {
  font-size: calc(20px + 1vw);
  line-height: 1em;
  padding-left: 0;
}
.LandingDescriptionLeft .Inventory div {
  border-left: #bcc1c5 1px solid;
  padding-left: 0.5em;
}
.LandingContentLeft {
  font-size: calc(15px + 1vw);
  line-height: 1em;
  padding-top: 1em;
  padding-left: 0.5em;
}

.BannerImage {
  position: absolute !important;
  bottom: 0;
  right: 0;
  max-width: 320px !important;
  min-width: 250px !important;
}

@media screen and (max-width: 991px) {
  .LandingDescriptionLeft .Brainquire {
    font-size: calc(30px + 2vw);
  }
  .LandingDescriptionLeft .Inventory {
    font-size: calc(15px + 1vw);
  }
}

@media screen and (max-width: 767px) {
  .LandingHeading {
    padding: 2em;
    min-height: calc(60vh - 60px);
  }
  .PageDown {
    display: none !important;
  }
  .LandingHeading .LandingHeadingHeader {
    font-size: calc(8px + 8vw);
  }
  .LandingSubHeader {
    font-size: calc(10px + 2.6vw);
    line-height: 1.5em;
  }
  .LandingDescriptionLeft .Brainquire {
    font-size: calc(40px + 1.5vw);
  }
  .LandingDescriptionLeft .Inventory {
    font-size: calc(20px + 1vw);
  }
  .BannerImage {
    max-width: 150px !important;
    min-width: 120px !important;
  }
}

.Banner {
  background-color: white;
  margin-bottom: -2em;
  width: 100%;
}

.HowMuchContainer {
  flex-grow: 1;
  background-color: white;
  width: 100%;
}
.HowMuchImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HowMuchImageInner {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.HowMuchPrice {
  color: #39718d;
  font-weight: bold;
  margin-top: 0.5em;
  font-size: 1em;
}

.HowMuchLicenseType {
  font-size: 1em;
  color: #404040;
}
.info-paragraph {
  margin-top: 2em;
  font-size: 1.5em;
  line-height: 1.3em;
  text-align: justify;
}

.info-cards {
  background-color: #f5f6f8;
  white-space: pre-wrap;
  max-width: 40em;
  margin: auto;
}
.info-title {
  font-size: 1.5em;
  line-height: 1.5em;
}
.info-phone {
  font-size: 1em;
}
.info-mail {
  font-size: 1em;
}
.info-signup {
  background-image: url("/assets/images/sidebar-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 63%;
}

div.parent {
  font-size: 0.8vw;
}

@media screen and (max-width: 1079px) {
  div.parent {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 600px) {
  div.parent {
    font-size: 2.2vw;
  }
  img:not(.yokatlas) {
    width: 100%;
  }

  .HowMuchImageInner > img {
    width: 25%;
  }

  .HowMuchLicenseType {
    font-size: 1.4em;
  }

  .HowMuchPrice {
    font-size: 1.4em;
  }

  .Footer {
    background-image: none;
  }
  .side-bar-image {
    display: none;
  }

  .report1 {
    position: relative;
  }

  .report2 {
    position: absolute;
  }
}
@media screen and (max-width: 760px) {
  .info-text {
    font-size: 2em;
  }

  @media screen and (max-width: 760px) {
    .NavBarLogoImage {
      padding-left: 20px;
    }

    .TypeDetailsCardIcon {
      flex-direction: column;
    }

    .TypeDetailsCardIcon > img {
      align-self: center;
    }
  }

  @media screen and (max-width: 400px) {
    .carousel-question-text {
      font-size: 1em !important;
    }
    .carousel-question-image button {
      font-size: 0.8em !important;
    }
  }
}
