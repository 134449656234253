.TypeCodeLetterUserList{
    width: 100px !important;
    text-align: right;
    justify-content: right;
}

.NewPage{
    width: 5;
    height: 5;
}

.hidden{
    display:none;
    visibility:hidden
}