.choice-button:hover {
  background: #00BC9D !important;
  color: white !important;
}

.choice-button {
  margin: 1em !important;
}

.carousel-question-image:focus {
  outline: none;
}

.test-step {
  padding-left: 1em !important;
  padding-right: 1em !important;
  flex-wrap: nowrap !important;
}

.test-grid {
  flex:1;
  max-width: 800px;
  margin: auto !important;
}

.TestSliderButtons{
  top: 18%;
  margin-right: 50px;
  margin-left: 50px;
}
.radio-success input:checked+label:after {
  background-color: green!important;
}
.radio-success input:checked+label:before {
  border-color: green!important;
}
.radio-danger input:checked+label:after {
  background-color: red!important;
}
.radio-danger input:checked+label:before {
  border-color: red!important;
}




@media screen and (max-width: 480px) {
  .TestSliderButtons {
    top: 20vw;
    margin-right: 8vw;
    margin-left: 8vw;
  }
}

/* .ScrollableTable {
  height: 100%;
  display: block;
}

.ScrollableTable tbody {
  overflow-y: auto;
  height: 45vh;
  width: 100%;
} */
/* 
.ScrollableTable thead {
  display: inline-block;
  width: 100%;
}

.ScrollableTable thead tr{
  display: block;
  width: 100%;
} */

.ScrollableTable thead tr:first-child > th {
  position: sticky !important;
  top: 0px;
  z-index: 2;
}
.ScrollableTable thead tr:first-child > th:nth-child(2) {
  border-top:1px solid rgba(34,36,38,.15);
}
.ScrollableTable thead tr:first-child > th:nth-child(3) {
  border-top:1px solid rgba(34,36,38,.15);
  border-top-right-radius: .28571429em;

}

.ScroollableTableContainer{
  height: 50vh;
  overflow: auto;
  padding: 0;
}
.ui.fluid.search.selection.dropdown.GradeDropDown .menu {
  max-height: 7.5rem!important;
  flex-grow: 2;
}