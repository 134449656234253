@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,800');
* {
  font-family: 'Open Sans';
  min-height: 0;
  min-width: 0;
}

div, p, span {
  font-family: 'Open Sans'!important;
}

.button {
  font-family: 'Open Sans', sans-serif !important;
}

a.active {
  color: #39718D!important;
  font-weight: 700;
}

.dark-text {
  color: #424242;
}

.blue-title {
  color: #39718D!important;
}

.rounded> :first-child>input {
  border-radius: 1em !important;
}

.rounded {
  border-radius: 1em !important;
}

.rounded-box {
  border-radius: 0.5em !important;
}

a.no-style {
  color: inherit!important;
}

.justify-center {
  display: flex !important;
  justify-content: center !important;
}

.p-0 {
  padding: 0em !important;
}

.p-1 {
  padding: 1em !important;
}

.p-2 {
  padding: 1.5em !important;
}

.p-3 {
  padding: 2em !important;
}

.p-4 {
  padding: 2.5em !important;
}

.p-5 {
  padding: 3em !important;
}

.pr-0 {
  padding-right: 0em !important;
}

.pr-1 {
  padding-right: 1em !important;
}

.pr-2 {
  padding-right: 1.5em !important;
}

.pr-3 {
  padding-right: 2em !important;
}

.pr-4 {
  padding-right: 2.5em !important;
}

.pr-5 {
  padding-right: 3em !important;
}

.pl-0 {
  padding-left: 0em !important;
}

.pl-1 {
  padding-left: 1em !important;
}

.pl-2 {
  padding-left: 1.5em !important;
}

.pl-3 {
  padding-left: 2em !important;
}

.pl-4 {
  padding-left: 2.5em !important;
}

.pl-5 {
  padding-left: 3em !important;
}

.pt-0 {
  padding-top: 0em !important;
}

.pt-1 {
  padding-top: 1em !important;
}

.pt-2 {
  padding-top: 1.5em !important;
}

.pt-3 {
  padding-top: 2em !important;
}

.pt-4 {
  padding-top: 2.5em !important;
}

.pt-5 {
  padding-top: 3em !important;
}

.pb-0 {
  padding-bottom: 0em !important;
}

.pb-1 {
  padding-bottom: 1em !important;
}

.pb-2 {
  padding-bottom: 1.5em !important;
}

.pb-3 {
  padding-bottom: 2em !important;
}

.pb-4 {
  padding-bottom: 2.5em !important;
}

.pb-5 {
  padding-bottom: 3em !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.px-2 {
  padding-right: 1.5em !important;
  padding-left: 1.5em !important;
}

.px-3 {
  padding-right: 2em !important;
  padding-left: 2em !important;
}

.px-4 {
  padding-right: 2.5em !important;
  padding-left: 2.5em !important;
}

.px-5 {
  padding-right: 3em !important;
  padding-left: 3em !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.py-2 {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.py-3 {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.py-4 {
  padding-top: 2.5em !important;
  padding-bottom: 2.5em !important;
}

.py-5 {
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}

.m-0 {
  margin: 0em !important;
}

.m-1 {
  margin: 1em !important;
}

.m-2 {
  margin: 1.5em !important;
}

.m-3 {
  margin: 2em !important;
}

.m-4 {
  margin: 2.5em !important;
}

.m-5 {
  margin: 3em  !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.mr-2 {
  margin-right: 1.5em !important;
}

.mr-3 {
  margin-right: 2em !important;
}

.mr-4 {
  margin-right: 2.5em !important;
}

.mr-5 {
  margin-right: 3em !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.ml-2 {
  margin-left: 1.5em !important;
}

.ml-3 {
  margin-left: 2em !important;
}

.ml-4 {
  margin-left: 2.5em !important;
}

.ml-5 {
  margin-left: 3em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 1.5em !important;
}

.mt-3 {
  margin-top: 2em !important;
}

.mt-4 {
  margin-top: 2.5em !important;
}

.mt-5 {
  margin-top: 3em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 1.5em !important;
}

.mb-3 {
  margin-bottom: 2em !important;
}

.mb-4 {
  margin-bottom: 2.5em !important;
}

.mb-5 {
  margin-bottom: 3em !important;
}

.mx-0 {
  margin-right: 0em !important;
  margin-left: 0em !important;
}

.mx-1 {
  margin-right: 1em !important;
  margin-left: 1em !important;
}

.mx-2 {
  margin-right: 1.5em !important;
  margin-left: 1.5em !important;
}

.mx-3 {
  margin-right: 2em !important;
  margin-left: 2em !important;
}

.mx-4 {
  margin-right: 2.5em !important;
  margin-left: 2.5em !important;
}

.mx-5 {
  margin-right: 3em !important;
  margin-left: 3em !important;
}

.my-0 {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.my-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.my-2 {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}

.my-3 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.my-4 {
  margin-top: 2.5em !important;
  margin-bottom: 2.5em !important;
}

.my-5 {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}



.ui.fluid.search.selection.dropdown > .menu {
  max-height: 12rem!important;
}


/* Print css */
/* 
@media print {
  @page {
    size: portrait;
  }
  .NavBar, .Footer {
    display: none!important;
  }
  .Content {
    visibility: visible;
  }
  .Layout {
    padding-top: 0!important;
  }
  .pageBreak {
    display: block!important;
    page-break-before: always!important;
  }
  .p-4 {
    padding: .3em!important;
  }
  .p-3 {
    padding: .3em!important;
  }
  .padded {
    padding: 1em!important;
  }
  .TypeCodeLetter {
    width: 100px!important;
  }
  .ReportHeader {
    font-size: 1.5em!important;
  }
  .large.header {
    font-size: 1.2em!important;
    margin: .3em!important;
  }
  .PrintButton {
    display: none!important;
  }
  .TypeDescriptionImage {
    width: 100px!important;
    margin-top: 1em!important;
  }
} */