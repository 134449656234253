.TypeCodeLetterContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.TypeCodeLetter{
  margin-left: -1em;
  margin-right: -1em;
  width: 140px !important;;
}



