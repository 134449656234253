.Layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F6F7F9;
    overflow: hidden;
    padding-top: 60px;

}
.Content {
    justify-content: center;  
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height:calc(100vh - 181.68px);
    padding-bottom: 2em;
}
.BackgroundImage {
    position: absolute;
    z-index: -1;
    right: -250px;
    bottom: 80px;
    width: 40%;
    height: 40%;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-position: bottom right;
    transform-origin: top right;
    transform: rotate(20deg) scale(2);
}
