.TypeCodeLetterContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.TypeCodeLetter{
  margin-left: -1em;
  margin-right: -1em;
  wdith: 100px !important;;
}



