@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,800);
.LoadingModalContainer {
    width: 100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    background-color: rgba(255,255,255,.1);
    z-index: 9000;
}
.LoginWrapper {
    height: 100vh;
    width: 100vw;
    background-color: #F6F7F9;
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
}

.FacebookButton {
  height: 10em;
  background-color: #3F5996
}


.ui.top.sidebar.SidebarMenu {
    top: 57px!important;
    z-index: 1;
}
.Layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F6F7F9;
    overflow: hidden;
    padding-top: 60px;

}
.Content {
    justify-content: center;  
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height:calc(100vh - 181.68px);
    padding-bottom: 2em;
}
.BackgroundImage {
    position: absolute;
    z-index: -1;
    right: -250px;
    bottom: 80px;
    width: 40%;
    height: 40%;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-position: bottom right;
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(20deg) scale(2);
            transform: rotate(20deg) scale(2);
}

.Footer {
  background-image:url('/assets/images/bg-brain-transparent.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size:15%;
  background-color: black;
}
.Main {
   height: 100%;
   flex:1 1;
   margin: 0!important;
}
.choice-button:hover {
  background: #00BC9D !important;
  color: white !important;
}

.choice-button {
  margin: 1em !important;
}

.carousel-question-image:focus {
  outline: none;
}

.test-step {
  padding-left: 1em !important;
  padding-right: 1em !important;
  flex-wrap: nowrap !important;
}

.test-grid {
  flex:1 1;
  max-width: 800px;
  margin: auto !important;
}

.TestSliderButtons{
  top: 18%;
  margin-right: 50px;
  margin-left: 50px;
}
.radio-success input:checked+label:after {
  background-color: green!important;
}
.radio-success input:checked+label:before {
  border-color: green!important;
}
.radio-danger input:checked+label:after {
  background-color: red!important;
}
.radio-danger input:checked+label:before {
  border-color: red!important;
}




@media screen and (max-width: 480px) {
  .TestSliderButtons {
    top: 20vw;
    margin-right: 8vw;
    margin-left: 8vw;
  }
}

/* .ScrollableTable {
  height: 100%;
  display: block;
}

.ScrollableTable tbody {
  overflow-y: auto;
  height: 45vh;
  width: 100%;
} */
/* 
.ScrollableTable thead {
  display: inline-block;
  width: 100%;
}

.ScrollableTable thead tr{
  display: block;
  width: 100%;
} */

.ScrollableTable thead tr:first-child > th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 2;
}
.ScrollableTable thead tr:first-child > th:nth-child(2) {
  border-top:1px solid rgba(34,36,38,.15);
}
.ScrollableTable thead tr:first-child > th:nth-child(3) {
  border-top:1px solid rgba(34,36,38,.15);
  border-top-right-radius: .28571429em;

}

.ScroollableTableContainer{
  height: 50vh;
  overflow: auto;
  padding: 0;
}
.ui.fluid.search.selection.dropdown.GradeDropDown .menu {
  max-height: 7.5rem!important;
  flex-grow: 2;
}
.TypeCodeLetterContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.TypeCodeLetter{
  margin-left: -1em;
  margin-right: -1em;
  wdith: 100px !important;;
}




.iconHover:hover i{
  font-family: icons !important;
}

.TypeDetailsSubHeader {
  color:  #36495D!important;
}
.TypeDetailListBox {
  background-color: #F5F6F8;
  padding: 1em;
  border-radius: 10px;  
}
.ReportHeader {
  font-family: 'Open Sans'!important;
  font-weight: 800!important;
  color: #36495D!important;
}

.center {
  width: 100%;
  text-align: center;
}

.ReportHeaderLight {
  font-weight: 700!important;
}


.ReportContainer .placeholder {
  -webkit-animation: none!important;
          animation: none!important;
}

.ReportContainer .line, .ReportContainer .paragraph::before {
  background-color: #F5F6F8!important;
}

@media  screen and (max-width: 600px) {
 .report-icon {

  display: none!important;

  }
}

#page-1.Print, #page-2.Print{
  width: 1161px!important;
}
.TypeCodeLetterContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.TypeCodeLetter{
  margin-left: -1em;
  margin-right: -1em;
  width: 140px !important;;
}




.ReportHeader {
  font-family: 'Open Sans'!important;
  font-weight: 800!important;
  color: #36495D!important;
}

.center {
  width: 100%;
  text-align: center;
}

.ReportHeaderLight {
  font-weight: 700!important;
}


.ReportContainer .placeholder {
  -webkit-animation: none!important;
          animation: none!important;
}

.ReportContainer .line, .ReportContainer .paragraph::before {
  background-color: #F5F6F8!important;
}

@media  screen and (max-width: 600px) {
 .report-icon {

  display: none!important;

  }
}

#page-1.Print, #page-2.Print{
  width: 1161px!important;
}
.TypeDescriptionCard{
    height: 200px;
    width: 352px;
    align-items: center;
}
.TypeCodeLetterUserList{
    width: 100px !important;
    text-align: right;
    justify-content: right;
}

.NewPage{
    width: 5;
    height: 5;
}

.hidden{
    display:none;
    visibility:hidden
}
.slide-container {
  margin: auto;
  display: inline;
  margin-bottom: 100px;
}
.citations {
  /* background-image: url('../../assets/images/uzman-bg.png') !important; */
  margin: auto;
}

.citations-header{
  font-family: 'Open Sans';
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: bold;
}
.each-fade {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExpertHeader{
  font-size: 14px;
}
.each-fade .image-container {
  margin-left: 50px;
  position: relative;
  float: left;
}

.each-fade .img-container {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 150px;
  height: 150px;
  border:'2px groove #39718D';
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: visible;
  border-top-color: transparent; 
}

.each-fade .quote-mark {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: visible;
  border-top-color: transparent;
}

.each-fade .author-container {
  color: #39BA9B;
  font-size: 20px;
  font-weight: 900;
  margin-top: 90px;
}


.each-fade .text-container {
  float: right;
  background: white;
  width: 800px;
  height: 280px;
  position:relative;
  margin-top: 75px;  
}

.each-fade .text {
  margin:15px;  
}

.PageDown {
  vertical-align: middle !important;
  animation: pulse 2s linear alternate-reverse infinite;
  cursor: pointer;
  text-align: center;
  bottom: 1em;
  position: absolute;
  left: 45%;
  line-height: 1;
}
@-webkit-keyframes pulse {
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulse {
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.LandingHeading {
  background-color: white;
  position: relative;
  display: flex;
  font-size: 3vw;
  width: 100%;
}

.LandingHeading .LandingHeading-Container {
  flex-grow: 1;
}
.LandingHeading .LandingHeadingHeader {
  font-size: calc(8px + 2.5vw);
  line-height: 1.2em;
  font-family: Arial;
  font-weight: lighter;
  letter-spacing: -2px;
}

.LandingSubHeader {
  font-size: calc(10px + 0.5vw);
  line-height: 1.2em;
}

.LandingDescriptionLeft {
  color: #bcc1c5;
}

.LandingDescriptionLeft .Brainquire {
  font-weight: bold;
  font-size: calc(50px + 1.5vw);
  align-items: center;
  display: flex !important;
  margin-bottom: 0.2em;
}

.LandingDescriptionLeft .Inventory {
  font-size: calc(20px + 1vw);
  line-height: 1em;
  padding-left: 0;
}
.LandingDescriptionLeft .Inventory div {
  border-left: #bcc1c5 1px solid;
  padding-left: 0.5em;
}
.LandingContentLeft {
  font-size: calc(15px + 1vw);
  line-height: 1em;
  padding-top: 1em;
  padding-left: 0.5em;
}

.BannerImage {
  position: absolute !important;
  bottom: 0;
  right: 0;
  max-width: 320px !important;
  min-width: 250px !important;
}

@media screen and (max-width: 991px) {
  .LandingDescriptionLeft .Brainquire {
    font-size: calc(30px + 2vw);
  }
  .LandingDescriptionLeft .Inventory {
    font-size: calc(15px + 1vw);
  }
}

@media screen and (max-width: 767px) {
  .LandingHeading {
    padding: 2em;
    min-height: calc(60vh - 60px);
  }
  .PageDown {
    display: none !important;
  }
  .LandingHeading .LandingHeadingHeader {
    font-size: calc(8px + 8vw);
  }
  .LandingSubHeader {
    font-size: calc(10px + 2.6vw);
    line-height: 1.5em;
  }
  .LandingDescriptionLeft .Brainquire {
    font-size: calc(40px + 1.5vw);
  }
  .LandingDescriptionLeft .Inventory {
    font-size: calc(20px + 1vw);
  }
  .BannerImage {
    max-width: 150px !important;
    min-width: 120px !important;
  }
}

.Banner {
  background-color: white;
  margin-bottom: -2em;
  width: 100%;
}

.HowMuchContainer {
  flex-grow: 1;
  background-color: white;
  width: 100%;
}
.HowMuchImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HowMuchImageInner {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.HowMuchPrice {
  color: #39718d;
  font-weight: bold;
  margin-top: 0.5em;
  font-size: 1em;
}

.HowMuchLicenseType {
  font-size: 1em;
  color: #404040;
}
.info-paragraph {
  margin-top: 2em;
  font-size: 1.5em;
  line-height: 1.3em;
  text-align: justify;
}

.info-cards {
  background-color: #f5f6f8;
  white-space: pre-wrap;
  max-width: 40em;
  margin: auto;
}
.info-title {
  font-size: 1.5em;
  line-height: 1.5em;
}
.info-phone {
  font-size: 1em;
}
.info-mail {
  font-size: 1em;
}
.info-signup {
  background-image: url("/assets/images/sidebar-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 63%;
}

div.parent {
  font-size: 0.8vw;
}

@media screen and (max-width: 1079px) {
  div.parent {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 600px) {
  div.parent {
    font-size: 2.2vw;
  }
  img:not(.yokatlas) {
    width: 100%;
  }

  .HowMuchImageInner > img {
    width: 25%;
  }

  .HowMuchLicenseType {
    font-size: 1.4em;
  }

  .HowMuchPrice {
    font-size: 1.4em;
  }

  .Footer {
    background-image: none;
  }
  .side-bar-image {
    display: none;
  }

  .report1 {
    position: relative;
  }

  .report2 {
    position: absolute;
  }
}
@media screen and (max-width: 760px) {
  .info-text {
    font-size: 2em;
  }

  @media screen and (max-width: 760px) {
    .NavBarLogoImage {
      padding-left: 20px;
    }

    .TypeDetailsCardIcon {
      flex-direction: column;
    }

    .TypeDetailsCardIcon > img {
      align-self: center;
    }
  }

  @media screen and (max-width: 400px) {
    .carousel-question-text {
      font-size: 1em !important;
    }
    .carousel-question-image button {
      font-size: 0.8em !important;
    }
  }
}

* {
  font-family: 'Open Sans';
  min-height: 0;
  min-width: 0;
}

div, p, span {
  font-family: 'Open Sans'!important;
}

.button {
  font-family: 'Open Sans', sans-serif !important;
}

a.active {
  color: #39718D!important;
  font-weight: 700;
}

.dark-text {
  color: #424242;
}

.blue-title {
  color: #39718D!important;
}

.rounded> :first-child>input {
  border-radius: 1em !important;
}

.rounded {
  border-radius: 1em !important;
}

.rounded-box {
  border-radius: 0.5em !important;
}

a.no-style {
  color: inherit!important;
}

.justify-center {
  display: flex !important;
  justify-content: center !important;
}

.p-0 {
  padding: 0em !important;
}

.p-1 {
  padding: 1em !important;
}

.p-2 {
  padding: 1.5em !important;
}

.p-3 {
  padding: 2em !important;
}

.p-4 {
  padding: 2.5em !important;
}

.p-5 {
  padding: 3em !important;
}

.pr-0 {
  padding-right: 0em !important;
}

.pr-1 {
  padding-right: 1em !important;
}

.pr-2 {
  padding-right: 1.5em !important;
}

.pr-3 {
  padding-right: 2em !important;
}

.pr-4 {
  padding-right: 2.5em !important;
}

.pr-5 {
  padding-right: 3em !important;
}

.pl-0 {
  padding-left: 0em !important;
}

.pl-1 {
  padding-left: 1em !important;
}

.pl-2 {
  padding-left: 1.5em !important;
}

.pl-3 {
  padding-left: 2em !important;
}

.pl-4 {
  padding-left: 2.5em !important;
}

.pl-5 {
  padding-left: 3em !important;
}

.pt-0 {
  padding-top: 0em !important;
}

.pt-1 {
  padding-top: 1em !important;
}

.pt-2 {
  padding-top: 1.5em !important;
}

.pt-3 {
  padding-top: 2em !important;
}

.pt-4 {
  padding-top: 2.5em !important;
}

.pt-5 {
  padding-top: 3em !important;
}

.pb-0 {
  padding-bottom: 0em !important;
}

.pb-1 {
  padding-bottom: 1em !important;
}

.pb-2 {
  padding-bottom: 1.5em !important;
}

.pb-3 {
  padding-bottom: 2em !important;
}

.pb-4 {
  padding-bottom: 2.5em !important;
}

.pb-5 {
  padding-bottom: 3em !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.px-2 {
  padding-right: 1.5em !important;
  padding-left: 1.5em !important;
}

.px-3 {
  padding-right: 2em !important;
  padding-left: 2em !important;
}

.px-4 {
  padding-right: 2.5em !important;
  padding-left: 2.5em !important;
}

.px-5 {
  padding-right: 3em !important;
  padding-left: 3em !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.py-2 {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.py-3 {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.py-4 {
  padding-top: 2.5em !important;
  padding-bottom: 2.5em !important;
}

.py-5 {
  padding-top: 3em !important;
  padding-bottom: 3em !important;
}

.m-0 {
  margin: 0em !important;
}

.m-1 {
  margin: 1em !important;
}

.m-2 {
  margin: 1.5em !important;
}

.m-3 {
  margin: 2em !important;
}

.m-4 {
  margin: 2.5em !important;
}

.m-5 {
  margin: 3em  !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.mr-2 {
  margin-right: 1.5em !important;
}

.mr-3 {
  margin-right: 2em !important;
}

.mr-4 {
  margin-right: 2.5em !important;
}

.mr-5 {
  margin-right: 3em !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.ml-2 {
  margin-left: 1.5em !important;
}

.ml-3 {
  margin-left: 2em !important;
}

.ml-4 {
  margin-left: 2.5em !important;
}

.ml-5 {
  margin-left: 3em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mt-2 {
  margin-top: 1.5em !important;
}

.mt-3 {
  margin-top: 2em !important;
}

.mt-4 {
  margin-top: 2.5em !important;
}

.mt-5 {
  margin-top: 3em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 1.5em !important;
}

.mb-3 {
  margin-bottom: 2em !important;
}

.mb-4 {
  margin-bottom: 2.5em !important;
}

.mb-5 {
  margin-bottom: 3em !important;
}

.mx-0 {
  margin-right: 0em !important;
  margin-left: 0em !important;
}

.mx-1 {
  margin-right: 1em !important;
  margin-left: 1em !important;
}

.mx-2 {
  margin-right: 1.5em !important;
  margin-left: 1.5em !important;
}

.mx-3 {
  margin-right: 2em !important;
  margin-left: 2em !important;
}

.mx-4 {
  margin-right: 2.5em !important;
  margin-left: 2.5em !important;
}

.mx-5 {
  margin-right: 3em !important;
  margin-left: 3em !important;
}

.my-0 {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.my-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.my-2 {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}

.my-3 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.my-4 {
  margin-top: 2.5em !important;
  margin-bottom: 2.5em !important;
}

.my-5 {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}



.ui.fluid.search.selection.dropdown > .menu {
  max-height: 12rem!important;
}


/* Print css */
/* 
@media print {
  @page {
    size: portrait;
  }
  .NavBar, .Footer {
    display: none!important;
  }
  .Content {
    visibility: visible;
  }
  .Layout {
    padding-top: 0!important;
  }
  .pageBreak {
    display: block!important;
    page-break-before: always!important;
  }
  .p-4 {
    padding: .3em!important;
  }
  .p-3 {
    padding: .3em!important;
  }
  .padded {
    padding: 1em!important;
  }
  .TypeCodeLetter {
    width: 100px!important;
  }
  .ReportHeader {
    font-size: 1.5em!important;
  }
  .large.header {
    font-size: 1.2em!important;
    margin: .3em!important;
  }
  .PrintButton {
    display: none!important;
  }
  .TypeDescriptionImage {
    width: 100px!important;
    margin-top: 1em!important;
  }
} */
.slider-container {
    margin: 0 auto;
    display: block;
  }
  .slider-container h3 {
    background: blue;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  }
  .slider-container .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  }
  .slider-container .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
  .slider-container .center h3 {
    opacity: 0.8;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .slider-container .content {
    padding: 20px;
    margin: auto;
    width: 90%;
  }
  .slider-container .slick-slide .image {
    padding: 10px;
  }
  .slider-container .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  }
  .slider-container .slick-slide img.slick-loading {
    border: 0;
  }
  .slider-container .slick-slider {
    margin: 30px auto 50px;
  }
  .slider-container .slick-dots {
    margin-left: 0;
  }
  .slider-container .slick-thumb {
    bottom: -45px;
  }
  .slider-container .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slider-container .slick-thumb li img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  .slider-container .slick-thumb li.slick-active img {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    .slider-container h3 {
      font-size: 24px;
    }
    .slider-container .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .slider-container .center .slick-center h3 {
      color: #e67e22;
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    .slider-container .center h3 {
      opacity: 0.8;
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
  }
  .slider-container .slick-vertical .slick-slide {
    height: 180px;
  }
  /* .slider-container .slick-arrow {
    background-color: grey;

  }
  .slider-container .slick-arrow:hover {
    background-color: grey;
  } */
  .slider-container .slick-arrow::before {
    color: black;
  }
   .slick-prev {
    z-index: 100;
    left: -40px !important;
  }
  .slick-next {
    z-index: 100;
    right: 0px !important;
  }

  .slider-container .slick-prev, .slick-next {
    font-size: 15px !important;
  }

  .slider-container .slick-prev:before, .slick-next:before  {
    content: '' !important;
  }

